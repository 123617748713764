import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.jpg";
import projImg2 from "../assets/img/project-img2.jpg";
import projImg3 from "../assets/img/project-img3.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Smart Mining Kit",
      technologies: "proteus , microchip studio , C  ",
      description: "A mining kit that interfaces an ATmega32 microcontroller with sensors such as a temperature/ humidity sensor, piezo plate, and gas sensor was designed for the safety of the miners.      ",
      imgUrl: projImg1,
    },
    {
      title: "Hotel Management System",
      technologies: "React JS , MySql , AWS , Node JS with Express JS ",
      description: "The project was an Automated Hotel Management System using ReactJS, NodeJS, and MySQL. It offers various features for managing hotel operations. The project implements best practices and gives an efficient hotel management experience.",
      imgUrl: projImg2,
    },
    // {
    //   title: "Business Startup",
    //   technologies: "proteus , microchip studio , C  ",
    //   description: "Design & Development",
    //   imgUrl: projImg3,
    // },
    
  ];

  return (
    <section className="project" id="projects">
      <Container className="projectContainer">
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>As a newcomer to the industry, I am thrilled to present my initial software projects, which showcase my enthusiasm for learning and applying various frameworks and fundamental programming languages. With these projects, I aimed to gain hands-on experience and demonstrate my ability to create functional and practical software solutions.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">What I have done so far </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="section">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
