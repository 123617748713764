import { Col } from "react-bootstrap";

export const ProjectCard = ({ title, description,technologies,imgUrl }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} className="imageSpace"/>
        <div className="proj-txtx">
          <h4>{title}</h4>
          <h6>Technologies used :<br></br><span>{technologies}</span></h6>
          <span><br></br>{description}</span>
        </div>
      </div>
    </Col>
  )
}
